import { getRandomInt } from "../plugins/constantFunction";

const allProvince = [
  { areaName: '北京市', areaCode: '110000' },
  { areaName: '天津市', areaCode: '120000' },
  { areaName: '河北市', areaCode: '130000' },
  { areaName: '山西省', areaCode: '140000' },
  { areaName: '内蒙古自治区', areaCode: '150000' },

  { areaName: '辽宁省', areaCode: '210000' },
  { areaName: '吉林省', areaCode: '220000' },
  { areaName: '黑龙江省', areaCode: '230000' },

  { areaName: '上海市', areaCode: '310000' },
  { areaName: '江苏省', areaCode: '320000' },
  { areaName: '浙江省', areaCode: '330000' },
  { areaName: '安徽省', areaCode: '340000' },
  { areaName: '福建省', areaCode: '350000' },
  { areaName: '江西省', areaCode: '360000' },
  { areaName: '山东省', areaCode: '370000' },

  { areaName: '河南省', areaCode: '410000' },
  { areaName: '湖北省', areaCode: '420000' },
  { areaName: '湖南省', areaCode: '430000' },
  { areaName: '广东省', areaCode: '440000' },
  { areaName: '广西壮族自治区', areaCode: '450000' },
  { areaName: '海南省', areaCode: '460000' },

  { areaName: '重庆市', areaCode: '500000' },
  { areaName: '四川省', areaCode: '510000' },
  { areaName: '贵州省', areaCode: '520000' },
  { areaName: '云南省', areaCode: '530000' },
  { areaName: '西藏自治区', areaCode: '540000' },

  { areaName: '陕西省', areaCode: '610000' },
  { areaName: '甘肃省', areaCode: '620000' },
  { areaName: '青海省', areaCode: '630000' },
  { areaName: '宁夏回族自治区', areaCode: '640000' },
  { areaName: '新疆维吾尔自治区', areaCode: '650000' },
]
const allSiChuan = [
  { areaCode: '510100', areaName: '成都市' },
  { areaCode: '510300', areaName: '自贡市' },
  // { areaCode: '510400', areaName: '攀枝花市' },
  { areaCode: '510500', areaName: '泸州市' },
  { areaCode: '510600', areaName: '德阳市' },
  // { areaCode: '510700', areaName: '绵阳市' },
  // { areaCode: '510800', areaName: '广元市' },
  // { areaCode: '510900', areaName: '遂宁市' },
  { areaCode: '511000', areaName: '内江市' },
  // { areaCode: '511100', areaName: '乐山市' },
  // { areaCode: '511300', areaName: '南充市' },
  { areaCode: '511400', areaName: '眉山市' },
  // { areaCode: '511500', areaName: '宜宾市' },
  // { areaCode: '511600', areaName: '广安市' },
  // { areaCode: '511700', areaName: '达州市' },
  { areaCode: '511800', areaName: '雅安市' },
  // { areaCode: '511900', areaName: '巴中市' },
  // { areaCode: '512000', areaName: '资阳市' },
  // { areaCode: '513200', areaName: '阿坝藏族羌族自治州' },
  { areaCode: '513300', areaName: '甘孜藏族自治州' },
  // { areaCode: '513400', areaName: '凉山彝族自治州' },
]
const allChengDu = [
  { areaCode: '510104', areaName: '锦江区' },
  { areaCode: '510105', areaName: '青羊区' },
  { areaCode: '510106', areaName: '金牛区' },
  { areaCode: '510107', areaName: '武侯区' },
  { areaCode: '510108', areaName: '成华区' },
  { areaCode: '510112', areaName: '龙泉驿区' },
  { areaCode: '510113', areaName: '青白江区' },
  { areaCode: '510114', areaName: '新都区' },
  { areaCode: '510115', areaName: '温江区' },
  { areaCode: '510116', areaName: '双流区' },
  { areaCode: '510117', areaName: '郫都区' },
  { areaCode: '510118', areaName: '新津区' },
  { areaCode: '510121', areaName: '金堂县' },
  { areaCode: '510129', areaName: '大邑县' },
  { areaCode: '510131', areaName: '蒲江县' },
  { areaCode: '510181', areaName: '都江堰市' },
  { areaCode: '510182', areaName: '彭州市' },
  { areaCode: '510183', areaName: '邛崃市' },
  { areaCode: '510184', areaName: '崇州市' },
  { areaCode: '510185', areaName: '简阳市' },
]

// 随机获取数组中的某几项
function getRandomArrayElements(arr: any, count: number) {
  var shuffled = arr.slice(0),
    i = arr.length,
    min = i - count,
    temp,
    index;
  while (i-- > min) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(min);
}

export default [
  // 获取地图标记点
  {
    url: "/mockMap",
    type: "get",
    response: () => {
      // const num = getRandomInt(0, 2, true, true)
      // const areaTotal = getRandomInt(1, 5, true, true)
      // const num = getRandomInt(0, 2, true, true)
      // const areaTotal = 7

      // const listType = [
      //   { level: 1, loadCode: 86, list: allProvince },
      //   { level: 2, loadCode: 510000, list: allSiChuan },
      //   { level: 3, loadCode: 510100, list: allChengDu },
      // ]
      // const xaxis = getRandomArrayElements(listType[num].list, areaTotal)

      // let yaxis = [];
      // for (let i = 0; i < areaTotal; i++) {
      //   yaxis.push(getRandomInt(1, 200, true, true))
      // }

      const loadCode = 510000
      const level = 2
      const xaxis = [
        { areaCode: '510100', areaName: '成都市' },
        { areaCode: '511400', areaName: '眉山市' },
        { areaCode: '510600', areaName: '德阳市' },
        { areaCode: '511800', areaName: '雅安市' },
        { areaCode: '510300', areaName: '自贡市' },
        { areaCode: '513300', areaName: '甘孜藏族自治州' },
        { areaCode: '510500', areaName: '泸州市' },
        { areaCode: '511000', areaName: '内江市' },
      ]
      const yaxis = [2998, 123, 100, 76, 38, 17, 15, 14]
      console.log(xaxis, yaxis)


      return {
        message: "成功",
        result: {
          xunit: "",
          yunit: "",
          // loadCode: listType[num].loadCode,
          // level: listType[num].level,
          loadCode: loadCode,
          level: level,
          xaxis: xaxis,
          yaxis: yaxis,
        },
        status: 0,
      };
    },
  },
];