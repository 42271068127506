/**
 * @Description: 公共常量和函数
 * @author: 蒋金津
 * @date: 2022/3/30 16:44
 */

/**
 * 获取一定范围内的随机整数
 * @param a
 * @param b
 * @param isIncludeMin 是否包含最小值
 * @param isIncludeMax 是否包含最大值
 */
function getRandomInt(a: number, b: number, isIncludeMin: boolean = true, isIncludeMax: boolean = true) {
  // 设置最大最小值
  let min, max;
  if(a <= b) {
    min = a;
    max = b;
  } else {
    min = b;
    max = a;
  }

  // [min, max]
  if(isIncludeMin && isIncludeMax) {
    return Math.floor(Math.random() * (max - min + 1 ) ) + min;
  }

  // [min, max) 等同于 [min, max - 1]
  if(isIncludeMin && !isIncludeMax) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  // (min, max] 等同于 [min + 1, max]
  if(!isIncludeMin && isIncludeMax) {
    return Math.floor(Math.random() * (max - min)) + (min + 1);
  }

  // (min, max) 等同于 [min + 1, max - 1]
  if(!isIncludeMin && !isIncludeMax) {
    if(max - min === 1) {
      return 0;
    }
    return Math.floor(Math.random() * (max - min - 2)) + (min + 1);
  }

  return 0;
}

/**
 * 解析url或get请求：去掉?和&，返回 { key1: value1, key2: value2 ...}
 */
function getParams(url: string) {
  let res: any = {};
  if (url.indexOf("?") !== -1) {
    let str = url.substr(1);
    let arr = str.split("&");
    for(let i = 0, len = arr.length; i < len; i ++) {
      // 截取key:value

      // 方法1：通过decodeURI 或 decodeURIComponent 进行解码
      // res[arr[i].split("=")[0]] = decodeURIComponent(arr[i].split("=")[1]);

      // 方法2：value中有特殊字符 如"="（token中带有=）
      if (arr[i].indexOf("=") !== -1) {
        res[arr[i].slice(0, arr[i].indexOf("="))] = arr[i].slice(arr[i].indexOf("=") + 1);
      }
    }
  }
  return res;
}

export { getRandomInt, getParams };