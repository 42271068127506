import Mock from "mockjs";
import { getRandomInt } from "../plugins/constantFunction";

// mock方法,详细的可以看官方文档
const Random = Mock.Random;

const lastNameList = ["赵", "钱", "孙", "李", "王", "顾", "苏", "诸葛", "纳兰"];
const lastName = lastNameList[getRandomInt(0, 8, true, true)];
const name = lastName + Random.cname();

const colorList = ["#0768D9", "#D4DEEC", "#E1FFBC", "#63E6B7", "#3DFFFF", "#488CF7", "#F2D777", "#01D9F4", "#B6ACF4"];

export default [
  // 获取用户信息
  {
    url: "/user/info",
    type: "get",
    response: () => {
      return {
        message: "成功",
        result: {
          id: Random.id(),
          name: name,
          orgName: "成都" + Random.ctitle(6, 10) + "公司",
          avatarUrl: Random.image("100x100", "#50B347", "#fff", lastName),
          avatarColor: colorList[getRandomInt(0, 8, true, true)],
          phone: "131" + Random.integer(10000000, 99999999),
          token: Random.guid() + Random.guid() + Random.guid() + Random.guid(),
        },
        status: 0,
      };
    },
  },
];
