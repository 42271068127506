import axios from 'axios';
import emessage from "../components/CommonComponents/EMessage/index";
import {getParams} from "./constantFunction";

console.log('是什么环境：', process.env.REACT_APP_ENV)
const urlParams = getParams(window.location.search)

// 方法1：直接修改Axios实例上defaults属性，主要用来设置通用配置
axios.defaults.headers["Content-Type"] = "application/json";

const service = axios.create({
  baseURL: process.env.REACT_APP_URL,
  timeout: 5000
})

const error = err => {
  if (err.response) {
    switch (err.response.status) {
      case 500:
        break;
      case 403:
        break;
      case 401:
        window.localStorage.removeItem("ecube_accessToken");
        break;
      default:
        break;
    }
  }
  return Promise.reject(err);
};

// 请求拦截 可在请求头中加入token等
service.interceptors.request.use(config => {
  config.headers["Content-Type"] = "application/json";

  // 从已发布链接进入，在跳转链接上带上固定shareUid，此时不需要token
  if(urlParams && urlParams.shareUid) {
    config.headers["shareUid"] = urlParams.shareUid;
  } else {
    if (window.localStorage.getItem("ecube_accessToken")) {
      config.headers["accessToken"] = window.localStorage.getItem("ecube_accessToken");
    }
  }

  // console.log(config)

  return config;
}, error);

// 响应拦截
service.interceptors.response.use(
  res => {

    // 当在2s内返回同一种错误信息时 该错误只需提示1次？？

    // 如果接口返回登录过期 是否应该阻止后面接口的请求？？

    if (res.status === 200) {
      // 错误类型：登录过期
      if((res.data.status === 1) || (res.data.status === 200001)) {
        emessage.error(res.data.message)
      }
      return res;
    }
  },
  error
)

export function get(url, params) {
  // 因为是get方法 所以如果有参数 且参数类型为Array 则转换为"1, 2, 3"的字符串形式
  if (params) {
    Object.keys(params).forEach(key => {
      if (Array.isArray(params[key])) {
        params[key] = params[key].toString();
      }
    });
  }
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    service
      .post(url, JSON.stringify(params))
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}
