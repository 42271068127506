import Mock from "mockjs";
import { getRandomInt } from "../plugins/constantFunction";

// mock方法,详细的可以看官方文档
const Random = Mock.Random;

let arr: any = [];
for (let i = 1; i < 100; i++) {
  let n = Random.integer(1, 2)
  arr.push({
    warnEvent: "乘客报警",
    projectName: "爱丽丝花园",
    usingUnitName: i + "栋",
    startTime: "2022-02-23 16:57",
    eventImgUrl: n % 2 ? "123" : ""
  })
}

let i = 0;
let n = Random.integer(0, 5)

export default [
  // 获取电梯报警
  {
    url: "/lift/alert",
    type: "get",
    response: () => {
      let list = arr.slice(i, n) || []
      i = n;
      n = i + Random.integer(0, 3);
      return {
        message: "成功",
        result: list,
        status: 0,
      };
    },
  },
];
