/**
 * @Description: 组件：全局消息提示
 * @author: 蒋金津
 * @date: 2022/3/28 14:20
 */
import React, { FC } from 'react'
import "./EMessage.scss";

export type MessageType = 'success' | 'warning' | 'error';

export interface MessageProps {
  text: string;
  id: string;
  type: MessageType;
}

const EMessage: FC<MessageProps> = (props: MessageProps) => {
  const { text, id, type } = props

  let color;
  switch (type) {
    case "success":
      color = "#2BB476";
      break;
    case "warning":
      color = "#E9A560";
      break;
    case "error":
      color = "#F84638";
      break;
    default:
      color = "#fff";
      break;
  }

  return (
    <div className="message" data-testid="test-message">
      <div className="message_content" style={{color: color, textShadow: `0 0 4px ${color}`}}>
        <img className="icon" src={require(`../../../assets/img/common/message_${type}.png`)} alt="背景" />
        <div className="text">
          {text}
        </div>
        {/* 背景 */}
        <svg className="message_bg_left" width="86" height="38" viewBox="0 0 86 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="86" height="38" fill={`url(#emessage_linear_l_${id})`} fillOpacity="0.5"/>
          <rect opacity="0.8" width="4" height="38" fill={color} />
          <defs>
            <linearGradient id={`emessage_linear_l_${id}`} x1="-5.43406e-07" y1="18.6415" x2="86" y2="18.6415" gradientUnits="userSpaceOnUse">
              <stop stopColor={color}/>
              <stop offset="1" stopColor={color} stopOpacity="0"/>
            </linearGradient>
          </defs>
        </svg>
        <svg className="message_bg_right" width="86" height="38" viewBox="0 0 86 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="86" y="38" width="86" height="38" transform="rotate(-180 86 38)" fill={`url(#emessage_linear_r_${id})`} fillOpacity="0.5"/>
          <rect opacity="0.8" x="86" y="38" width="4" height="38" transform="rotate(-180 86 38)" fill={color}/>
          <defs>
            <linearGradient id={`emessage_linear_r_${id}`} x1="86" y1="56.6415" x2="172" y2="56.6415" gradientUnits="userSpaceOnUse">
              <stop stopColor={color}/>
              <stop offset="1" stopColor={color} stopOpacity="0"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  )
}

export default EMessage