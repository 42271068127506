import Mock from 'mockjs';

import maps from './maps';
import user from "./user";
import groups from "./groups";
import liftAlert from "./liftAlert";

const mocks = [
  ...maps,
  ...user,
  ...groups,
  ...liftAlert,
]

// mock请求方法放在这里统一处理，对url进行正则处理 使其可以传参
for (const i of mocks) {
  Mock.mock(RegExp(i.url + ".*"), i.type, i.response)
}