import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { TransitionGroup } from 'react-transition-group';
import ETransition from "../ETransition/ETransition";
import EMessage, { MessageType } from './EMessage';

export interface MessageApi {
  success: (text: string) => void;
  warning: (text: string) => void;
  error: (text: string) => void;
}

export interface Notice {
  text: string;
  id: string;
  type: MessageType;
}

let seed = 0
const now = Date.now()
const getUuid = (): string => {
  const id = seed
  seed += 1
  return `MESSAGE_${now}_${id}`
}

let add: (notice: Notice) => void

export const MessageContainer = () => {
  const [notices, setNotices] = useState<Notice[]>([]);
  const timeout = 3 * 1000;
  const maxCount = 10;

  const remove = (notice: Notice) => {
    const { id } = notice

    setNotices((prevNotices) => (
      prevNotices.filter(({ id: itemId }) => id !== itemId)
    ))
  }

  add = (notice: Notice) => {

    // 消息去重：当消息队列中存在和该条消息一样的内容时，不会添加到消息队列中；如果不存在，则走正常移除流程。
    let isSame = notices.filter(item => notice.text === item.text);
    if(!isSame.length) {
      setNotices((prevNotices) => [...prevNotices, notice])
      setTimeout(() => {
        remove(notice)
      }, timeout)
    }

    // 无任何操作
    // setNotices((prevNotices) => [...prevNotices, notice])
    // setTimeout(() => {
    //   remove(notice)
    // }, timeout)

  }

  useEffect(() => {
    if (notices.length > maxCount) {
      const [firstNotice] = notices
      remove(firstNotice)
    }
  }, [notices])

  return (
    <div className="message-container">
      <TransitionGroup>
        {
          notices.map(({ text, id, type }) => (
            <ETransition timeout={300} in animation="slide-in-top" key={id}>
              <EMessage type={type} id={id} text={text} />
            </ETransition>
          ))
        }
      </TransitionGroup>

    </div>
  )
}

let el = document.querySelector('#ecube-message-wrapper')
if (!el) {
  el = document.createElement('div')
  el.className = 'ecube-message-wrapper'
  el.id = 'ecube-message-wrapper'
  document.body.append(el)
}

ReactDOM.render(
  <MessageContainer />,
  el
)

const api: MessageApi = {
  success: (text) => {
    add({
      text,
      id: getUuid(),
      type: 'success'
    })
  },
  warning: (text) => {
    add({
      text,
      id: getUuid(),
      type: 'warning'
    })
  },
  error: (text) => {
    add({
      text,
      id: getUuid(),
      type: 'error'
    })
  }
}

export default api