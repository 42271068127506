import React from 'react';
import Router from "./router";

console.log('-----------流水线测试----------------------')

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
