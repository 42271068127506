/**
 * @Description: 组件：CSSTransition
 * @author: 蒋金津
 * @date: 2022/3/28 14:19
 */
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

type AnimationName = 'zoom-in-top' | 'zoom-in-left' | 'zoom-in-bottom' | 'zoom-in-right' | 'slide-in-top'

type TransitionProps = CSSTransitionProps & {
  animation?: AnimationName,
  wrapper?: boolean,
}

const ETransition: React.FC<TransitionProps> = (props) => {
  const {
    children,
    classNames,
    animation,
    wrapper,
    ...restProps
  } = props

  return (
    <CSSTransition classNames={classNames ? classNames : animation}{...restProps}>
      {wrapper ? <div>{children}</div> : children}
    </CSSTransition>
  )
}
ETransition.defaultProps = {
  unmountOnExit: true,
  appear: true,
}

export default ETransition;