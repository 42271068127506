// 分组情况
const group = [
  {
    id: 1,
    name: "模板",
    count: 4
  },
  {
    id: 3,
    name: "维保",
    count: 1
  },
  {
    id: 2,
    name: "未分组",
    count: 0
  },
]
// 列表
const list = [
  {
    groupId: 1,
    groupName: "模板",
    id: 1,
    screenType: "screen1",
    imgUrl: 1,
    name: "太行云梯智慧物业监管中心",
    size: { width: 1920, height: 1080 },
    status: 0,
    orgType: [0]
  },
  {
    groupId: 1,
    groupName: "模板",
    id: 2,
    screenType: "screen2",
    imgUrl: 2,
    name: "太行云梯电梯智慧监管平台",
    size: { width: 1920, height: 1080 },
    status: 0,
    orgType: [0]
  },
  {
    groupId: 1,
    groupName: "模板",
    id: 3,
    screenType: "screen3",
    imgUrl: 3,
    name: "太行云梯智慧维保监管中心",
    size: { width: 1920, height: 1080 },
    status: 0,
    orgType: [0]
  },
  {
    groupId: 1,
    groupName: "模板",
    id: 4,
    screenType: "screen4",
    imgUrl: 1,
    name: "崇州市电梯智慧监管大屏",
    size: { width: 1920, height: 1080 },
    status: 0,
    orgType: [0]
  },
  {
    groupId: 3,
    groupName: "维保",
    id: 5,
    screenType: "screen5",
    imgUrl: 5,
    name: "电梯智慧监管平台",
    size: { width: 1920, height: 1080 },
    status: 0,
    orgType: [0, 1, 2]
  },
]

function getParams(oldUrl: string) {
  const url = oldUrl.split("?");
  const arr = (url.length > 1) ? url[1].split("&") : [];
  let params: Array<any> = []
  // for(let i = 0, len = arr.length; i < len; i++) {
  //   const p = arr[i].split("=")
  //   params.push({key: p[0], value: p[1]})
  // }
  arr.map(item => {
    const p = item.split("=")
    params.push({ key: p[0], value: p[1] })
  })
  return params
}

interface ReqType {
  url: string,
  type: string,
  body?: any,
}

export default [
  {
    url: "/group/list",
    type: "get",
    response: () => {
      return {
        message: "成功",
        result: group,
        status: 0,
      };
    }
  },
  // 获取用户信息
  {
    url: "/group/screen/list",
    type: "get",
    response: (req: ReqType) => {
      let data = list;
      if (req.url) {
        const params = getParams(req.url)
        params.map(p => {
          if (p.key === "id") {
            if (p.value == -1) {
              data = list;
            } else {
              data = list.filter(item => item.groupId == p.value);
            }
          }
        })
      }

      return {
        message: "成功",
        result: {
          data: data,
          total: data.length
        },
        status: 0,
      };
    },
  },
];
