// 路由懒加载
import React, { lazy } from "react";

interface RouterType {
  name?: string,
  path: string,
  children?: Array<RouterType>,
  component: any,
  meta: {
    title: string
  }
}

// 菜单路由
const menuRoutes: Array<RouterType> = [
  {
    name: "Home",
    path: "/home",
    component: lazy(() => import("../pages/Home/Home")),
    meta: {
      title: "我的大屏",
    },
  },
  // {
  //   name: "TemplateLibrary",
  //   path: "/templateLibrary",
  //   component: lazy(() => import("../pages/TemplateLibrary/TemplateLibrary")),
  //   meta: {
  //     title: "模板库",
  //   },
  // },
  // {
  //   name: "ScreenEdit",
  //   path: "/screen/edit",
  //   component: lazy(() => import("../pages/ScreenEdit/ScreenEdit")),
  //   meta: {
  //     title: "大屏编辑",
  //   },
  // },
]

// 其他子页面路由
const otherRoutes: Array<RouterType> = [
  {
    name: "Screen1",
    path: "/project/screen1/:pid",
    component: lazy(() => import("../pages/Screen1/Screen1")),
    meta: {
      title: "大屏1",
    },
  },
  {
    name: "Screen2",
    path: "/project/screen2/:pid",
    component: lazy(() => import("../pages/Screen2/Screen2")),
    meta: {
      title: "大屏2",
    },
  },
  {
    name: "Screen3",
    path: "/project/screen3/:pid",
    component: lazy(() => import("../pages/Screen3/Screen3")),
    meta: {
      title: "大屏3",
    },
  },
  {
    name: "Screen4",
    path: "/project/screen4/:pid",
    component: lazy(() => import("../pages/Screen4/Screen4")),
    meta: {
      title: "大屏4",
    },
  },
  {
    name: "Screen5",
    path: "/project/screen5/:pid",
    component: lazy(() => import("../pages/Screen5/Screen5")),
    meta: {
      title: "大屏5",
    },
  },
]

// 路由配置表
const routes = [
  ...menuRoutes,
  ...otherRoutes,
]

export { menuRoutes, routes };
