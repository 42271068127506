/**
 * @Description: 加载组件
 * @author: 蒋金津
 * @date: 2022/4/2 16:37
 */
import React from "react";
import "./ELoading.scss";

interface propsType {
  bg?: string
}
export default function ELoading(props: propsType) {
  const { bg } = props;

  return (
	<div data-component="ELoading" style={{background: bg}}>
    <div className="loading_x">
      <img className="img_loading" src={require('../../../assets/img/common/loading.gif')} alt="" />
    </div>
  </div>
  )
}